<template>
  <transition name="fade">
    <form @keydown="form.onKeydown($event)" @submit.prevent="editTask" v-if="onProgress === false">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('type_id') }">
              <div class="form-group">
                <label class="font-medium font-small">
                  Состояние
                </label>
                <multiselect :allow-empty="false"
                             :options="task_type_states"
                             :searchable="false"
                             @input="() => {
                               form.type_state_id = task_type_state ? task_type_state.id : null;
                             }"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Состояние задачи"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Состояние задачи"
                             track-by="id"
                             required
                             v-model="task_type_state">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="type_id"/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('term_at') }">
              <div class="form-group">
                <label>
                  <v-date-picker
                      :masks="date_options.masks"
                      :max-date="date_options.maxDate"
                      :min-date="date_options.minDate"
                      :mode="'date'"
                      :model-config="date_options.formatDate"
                      :update-on-input="false"
                      color="blue"
                      locale="ru"
                      v-model="form.term_at"
                      trim-weeks>
                    <template v-slot="{inputValue, inputEvents}">
                      <input :value="inputValue"
                             class="form-control"
                             id="filter-by-date"
                             placeholder="Дата выполнения"
                             v-on="inputEvents"/>
                    </template>
                  </v-date-picker>
                  <HasError :form="form" field="term_at"/>
                </label>
              </div>
            </div>
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('term_time') }">
              <div class="form-group">
                <label for="term_time">
                  <input type="time" class="form-control" id="term_time" name="term_time" v-model="form.term_time">
                </label>
                <HasError :form="form" field="term_time"/>
              </div>
            </div>
          </div>

          <div class="form-group">
              <textarea class="form-control"
                        maxlength="3000"
                        id="comment"
                        rows="3"
                        :required="this.task.type_state_id !== this.form.type_state_id"
                        placeholder="Комментарий"
                        v-model="form.comment">
              </textarea>
            <HasError :form="form" field="comment"/>
          </div>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

          <br/>

          <Button :disabled="!validateData || form.busy"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-success pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-save"></i>
          </Button>
        </div>
      </div>
    </form>
    <loading-processing v-else></loading-processing>
  </transition>
</template>

<script>
import Form from "vform";
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import VCalendar from 'v-calendar';
import Vue from "vue";

Form.axios = API.apiClient;

Vue.use(VCalendar, {});

moment.locale('ru');

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'edit-task-state-personal-form',

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  props: {
    task: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: new Form({
      comment: null,
      is_updated_task: true,
      is_personal_task: true,
    }),

    task_type_states: [],
    task_type_state: null,

    onProgress: true,

    date_options: {
      minDate: moment().format(DATE_FORMAT),
      maxDate: moment().add(3, 'M').format(DATE_FORMAT),
      formatDate: {
        type: 'string',
        mask: DATE_FORMAT,
      },
      masks: {
        input: DATE_FORMAT,
      },
    },
  }),

  async created() {
    try {
      this.task_type = this.task.type;
      this.form.type_id = this.task_type.id;

      this.task_type_states = this.task.type.states;
      this.task_type_state = this.task.type_state;
      this.form.type_state_id = this.task_type_state.id;

      this.form.type_status_id = this.task.type_status.id;

      if (this.task.term_at) {
        this.form.term_at = this.task.term_at;
        this.form.term_time = this.task.term_time;
      }

    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.form.type_id && this.form.type_state_id;
    },
  },

  methods: {
    async editTask() {
      if (this.form.term_at) {
        this.form.term_at = moment(this.form.term_at).format(DATE_FORMAT);
      }

      await this.form.put('/tasks/edit/' + this.task.id).then(() => {
        showSuccess();
        this.EventBus.$emit('hide-edit-task-modal');
        this.EventBus.$emit('task-items-reload');
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style>
.multiselect__content-wrapper {
  position: relative;
}
</style>
